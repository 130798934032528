import FormGenerator from "@/components/kit/FormGenerator";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { useForm } from "react-hook-form";
import { EpisodeFormSchemaFields, seasonSchema } from "./schema";
import { IEpisodeCreateFormType } from "./form-type";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { getAxiosError } from "@/utils/get-axios-error";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { contentService } from "@/api/services/content";
import { slugify } from "@/utils/slugify";
import { IContentListResponse } from "@/api/types/content";
import { IFormGeneratorGeneralSchemaType } from "@/types/form-generator-schema-type";

interface Props {
    parentId: number;
    id?: number;
    episodeCount: number | undefined;
    seasonNo: number;
    onClose: () => void;
    setData: React.Dispatch<React.SetStateAction<IContentListResponse[] | undefined>>;
}

const EpisodeForm: React.FC<Props> = ({ parentId, id, episodeCount, onClose, setData, seasonNo }) => {
    const isEditingRule = Boolean(id);
    const [episodeOrderNumber, setEpisodeOrderNumber] = useState<number>(episodeCount ? episodeCount + 1 : 1);
    const [formFieldsSchema, setFormFieldsSchema] = useState<Record<EpisodeFormSchemaFields, IFormGeneratorGeneralSchemaType>>(seasonSchema);
    const [loading, setLoading] = useState<boolean>(isEditingRule);
    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        reset,
    } = useForm<IEpisodeCreateFormType>({
        defaultValues: generateDefaultValueFromFormSchema(formFieldsSchema),
        mode: "all",
        // resolver: yupResolver(createContentValidationSchema),
    });

    const onSubmitForm = async (_val: IEpisodeCreateFormType) => {
        try {
            setLoading(true);
            const payload = {
                parentId,
                slug: slugify(_val.en_title),
                ml_name: {
                    en: _val.en_title,
                    ar: _val.ar_title,
                },
                ml_description: {
                    en: _val.en_description,
                    ar: _val.ar_description,
                },
                typeId: 4,
                status: String(_val.sharing_state),
                ...(_val.thumbnailLandscapeId ? { thumbnailLandscapeId: _val.thumbnailLandscapeId } : undefined),
                ...(_val.thumbnailPortraitId ? { thumbnailPortraitId: _val.thumbnailPortraitId } : undefined),
                ...(_val.mainVideo ? { mainVideo: _val.mainVideo } : undefined),
                episode: episodeOrderNumber,
                season: seasonNo,
            };

            if (isEditingRule) {
                const response = await contentService.updateEpisode(Number(id), payload);
                setData((prev) => (prev ? prev.map((item) => (item.id === Number(id) ? response.data.data : item)) : undefined));
            } else {
                const response = await contentService.createEpisode(payload);
                setData((prev) => (prev ? [...prev, response.data.data] : undefined));
            }

            onClose();
            enqueueSnackbar(`${isEditingRule ? "Episode updated" : "New episode created"} successfully`, { variant: "success" });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    const getEditData = async () => {
        try {
            setLoading(true);
            const response = await contentService.show(Number(id));
            const { ml_description, ml_name, thumbnailLandscape, thumbnailPortrait, episode, mainVideo } = response.data.data;
            reset({
                ar_title: ml_name.ar,
                ar_description: ml_description.ar,
                en_title: ml_name.en,
                en_description: ml_description.en,
                sharing_state: status === "Draft" ? 1 : 2,
                ...(thumbnailLandscape ? { thumbnailLandscapeId: thumbnailLandscape.id } : undefined),
                ...(thumbnailPortrait ? { thumbnailPortraitId: thumbnailPortrait.id } : undefined),
                ...(mainVideo ? { mainVideo: mainVideo.id } : undefined),
            });
            setEpisodeOrderNumber(episode!);
            setFormFieldsSchema((prev) => ({
                ...prev,
                ...(thumbnailLandscape
                    ? {
                          thumbnailLandscapeId: {
                              ...prev.thumbnailLandscapeId,
                              props: {
                                  ...prev.thumbnailLandscapeId.props,
                                  previewImage: thumbnailLandscape.url,
                                  previewSize: thumbnailLandscape.size,
                                  previewName: thumbnailLandscape.url.substring(thumbnailLandscape.url.lastIndexOf("/") + 1),
                              },
                          },
                      }
                    : undefined),
                ...(thumbnailPortrait
                    ? {
                          thumbnailPortraitId: {
                              ...prev.thumbnailPortraitId,
                              props: {
                                  ...prev.thumbnailPortraitId.props,
                                  previewImage: thumbnailPortrait.url,
                                  previewSize: thumbnailPortrait.size,
                                  previewName: thumbnailPortrait.url.substring(thumbnailPortrait.url.lastIndexOf("/") + 1),
                              },
                          },
                      }
                    : undefined),
                ...(mainVideo
                    ? {
                          mainVideo: {
                              ...prev.mainVideo,
                              props: {
                                  ...prev.mainVideo.props,
                                  previewImage: mainVideo.thumbnail?.url,
                                  previewName: mainVideo.title,
                              },
                          },
                      }
                    : undefined),
            }));
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    const footer = (
        <Stack spacing={2} direction={"row"}>
            <LoadingButton type="submit" disabled={!isValid || loading} loading={loading} variant="contained">
                {isEditingRule ? "Update" : "Create"} Episode
            </LoadingButton>
            <Button type="button" onClick={onClose}>
                Cancel
            </Button>
        </Stack>
    );

    useEffect(() => {
        isEditingRule && getEditData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditingRule]);

    return (
        <FormGenerator schema={formFieldsSchema} control={control} errors={errors} onSubmit={handleSubmit(onSubmitForm)} loading={loading} footer={footer} />
    );
};

export default EpisodeForm;
