import { Link, useParams } from "react-router-dom";
import { Button } from "@mui/material";

import { IContentCreateFormType } from "../forms/addMovieForm/type";
import AddForm from "../forms/addMovieForm";

import Styled from "./style";

import { IContentCreatePayload, IContentUpdatePayload } from "@/api/types/content";
import { useCreateContent, useUpdateContent } from "@/api/hooks/useContents";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { CONTENT_TYPE } from "@/enums/content-type";

export default function CreateMovie() {
    const { id } = useParams();
    const { mutate: createContent } = useCreateContent();
    const { mutate: updateContent } = useUpdateContent();

    const handleSubmit = (formVal: IContentCreateFormType) => {
        const body: IContentCreatePayload | IContentUpdatePayload = {
            ageRangeId: Number(formVal.age_range),
            genres: formVal.genre,
            imdb: formVal.imdb,
            introLength: Number(formVal.intro_duration),
            isEntertainment: formVal.category === 2,
            isEducation: formVal.category === 1,
            length: Number(formVal.duration),

            ml_name: {
                en: formVal.en_title,
                ar: formVal.ar_title,
            },
            ml_description: {
                en: formVal.en_description,
                ar: formVal.ar_description,
            },
            slug: formVal.slug,
            status: String(formVal.sharing_state),
            tags: formVal.tag,
            thumbnailLandscapeId: formVal.thumbnailLandscapeId,
            thumbnailPortraitId: formVal.thumbnailPortraitId,
            publisherId: formVal.publisherId,
            typeId: CONTENT_TYPE.MOVIE,
        };

        if (id) {
            updateContent({ id: Number(id), body });
        } else {
            body.mainVideo = formVal.mainVideo;
            body.trailerVideo = formVal.trailerVideo;
            createContent(body);
        }
    };

    return (
        <Styled>
            <div className="page-header">
                <h1 className="page-title">{id ? "Edit" : "Create"} a Video</h1>
                <Button className="back" component={Link} to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ROOT.ABSOLUTE}>
                    Back To Content List
                </Button>
            </div>

            <div className="page-content">
                <AddForm onSubmit={handleSubmit} />
            </div>
        </Styled>
    );
}
