import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import { IAxiosResponse } from "@/types/axios-response";
import { IImageDeleteResponse, IImageListResponse, IImageUploadResponse } from "@/api/types/image";
import { ITableParams, ITableResponse } from "@/types/table";

export const imageService = {
    list(params?: ITableParams) {
        return AxiosInstance.post<IAxiosResponse<ITableResponse<IImageListResponse>>>(API_ROUTES.IMAGE.LIST, params);
    },
    create(payload?: FormData) {
        return AxiosInstance.post<IAxiosResponse<IImageUploadResponse>>(API_ROUTES.IMAGE.CREATE, payload, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse<IImageDeleteResponse>>(API_ROUTES.IMAGE.DELETE(id));
    },
};
