import { styled, Theme } from "@mui/material";

type Style = {
    disabled: boolean,
};

export default styled("div")<Style>(
    ({ theme, disabled }: { theme: Theme, disabled: boolean }) => `
    display: flex;
    flex-direction: column;
    padding: .5em;
    height: auto;
    font-weight: bold;
    flex-direction: column;
    min-height: 125px;
    border: 1px solid ${theme.palette.mode === "light" ? theme.palette.grey[300] : theme.palette.grey[800]};
    border-radius: 18px;
    
    .text {
        padding: 0 10px;
        font-size: 13px;
        margin-top: -15px;
        margin-bottom: 10px;
        width: auto;
        max-width: 160px;
        display: inline-flex;
        background: ${theme.palette.background.default};
    }

    .contents {
        border-radius:18px;
        opacity: ${disabled ? "0.5" : "1"};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .actions {

            display: flex;
            width: 100%;

            .select-box {
                width: 120px;
                min-width: 50px;
                max-height: 50px;
                height: 50px;

                .selectBox-outlined   {
                    padding: 0 10px;
                }

            }

            .upload-btn {
                width: 120px;
                margin: 0 10px;
            }

            .fake-input {
                z-index: 0;
                display:flex;
                min-width: 280px;
                cursor: pointer;
                position: relative;
                width: inherit;
                height: 50px;
                padding: 5px 15px;
                display: inline-flex;
                align-items:center;
                font-weight: bold;
                border-radius:18px;
                background: ${theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[800]};

                .progress {
                    z-index: 5;
                    top: 0px;
                    left: 0px;
                    height: 3px;
                    width: auto;
                    position:  absolute;
                    background-color: ${theme.palette.primary.light};
                }

                .placeholder {
                    display:flex;
                    flex-direction: row;
                    align-items:center;

                    span {
                        font-size: 13px;
                        font-weight: normal;
                        margin-inline-start: 10px;
                    }
                }

                .clear {
                    margin-left: auto;
                }
                
                input {
                    display: none;
                }

                .icon {
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    right: 10px;
                    top: 10px;
                }
            }
        }

        .list {
            margin-top: .5em;
            display: flex;
            width: 100%;

            .MuiChip-root {
                margin-inline: 3px;
            } 
        }

       
    }

`
);
