import { IModalProps } from "@/types/modals";
import { useCallback, useEffect, useState } from "react";
import {
    SelectImageModalFooterButtonsStyled,
    SelectImageModalFooterPaginationStyled,
    SelectImageModalFooterStyled,
    SelectImageModalItemContentStyled,
    SelectImageModalItemHeaderStyled,
    SelectImageModalItemStyled,
    SelectImageModalListBodyLoadingStyled,
    SelectImageModalListBodyStyled,
    SelectImageModalListHeaderStyled,
    SelectImageModalListWrapperStyled,
    SelectImageModalPreviewStyled,
    SelectImageModalStyled,
} from "./styled";
import { imageService } from "@/api/services/image";
import { IImageListResponse } from "@/api/types/image";
import { getAxiosError } from "@/utils/get-axios-error";
import { enqueueSnackbar } from "notistack";
import { ITablePaginate, ITableParams } from "@/types/table";
import { Button, CircularProgress, InputAdornment, OutlinedInput, Pagination } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDebounce } from "@/hooks/useDebounce";
import { useIsMount } from "@/hooks/useIsMount";
import { IMAGE_MODAL_PER_PAGE } from "@/constants/image-modal-configs";
import { stringLimiter } from "@/utils/string-limiter";

export interface SelectImageModalProps {
    preview?: boolean;
    onChange: (...event: any[]) => void;
}
const SelectImageModal: React.FC<IModalProps<SelectImageModalProps>> = ({ data: { onChange }, closeModal }) => {
    const isMount = useIsMount();
    const [listLoading, setListLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState(false);
    const [data, setData] = useState<IImageListResponse[]>();
    const [pagination, setPagination] = useState<ITablePaginate>();
    const [queryParams, setQueryParams] = useState<ITableParams>();
    const [selectedImage, setSelectedImage] = useState<IImageListResponse>();
    const [search, setSearch] = useState("");

    const fetchImageList = async () => {
        try {
            setListLoading(true);
            const response = await imageService.list(queryParams);
            setData(response.data.data.items);
            setPagination(response.data.data.pagination);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setListLoading(false);
            setSearchLoading(false);
        }
    };

    const handlePaginationModelChange = useCallback((page: number) => {
        setQueryParams((prev) => ({ ...prev, take: IMAGE_MODAL_PER_PAGE, skip: (page - 1) * IMAGE_MODAL_PER_PAGE }));
    }, []);

    const fetchDataDebounce = useDebounce(() => {
        setQueryParams((prev) => ({ ...prev, search }));
    });

    const onSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
        setSearchLoading(true);
    };

    const clearSearch = () => {
        setSearch("");
        setSearchLoading(true);
    };

    const handleSelectImage = (imageIndexId: number) => {
        const image = data?.find((item) => item.id === imageIndexId);
        setSelectedImage(image);
    };
    const handleSelectImageForm = () => {
        onChange(selectedImage);
        closeModal();
    };

    useEffect(() => {
        if (!isMount) fetchDataDebounce();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        fetchImageList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams]);

    return (
        <>
            <SelectImageModalStyled>
                <SelectImageModalListWrapperStyled>
                    <SelectImageModalListHeaderStyled>
                        <OutlinedInput
                            value={search}
                            onChange={onSearch}
                            size="small"
                            placeholder="Image Search ..."
                            endAdornment={
                                <InputAdornment position="end">
                                    {searchLoading ? (
                                        <CircularProgress size={24} />
                                    ) : search.length ? (
                                        <CloseIcon sx={{ cursor: "pointer" }} onClick={clearSearch} />
                                    ) : null}
                                </InputAdornment>
                            }
                        />
                    </SelectImageModalListHeaderStyled>
                    {listLoading ? (
                        <SelectImageModalListBodyLoadingStyled>
                            <CircularProgress size={24} />
                        </SelectImageModalListBodyLoadingStyled>
                    ) : (
                        <SelectImageModalListBodyStyled>
                            {data?.map((item) => (
                                <SelectImageModalItemStyled active={selectedImage?.id === item.id} onClick={() => handleSelectImage(item.id)}>
                                    <SelectImageModalItemHeaderStyled>
                                        {item.url ? <img src={item.url} alt={item.originalName} /> : <>No Image</>}
                                    </SelectImageModalItemHeaderStyled>
                                    <SelectImageModalItemContentStyled>
                                        <p>{stringLimiter(item.title.length ? item.title : item.originalName, 40)}</p>
                                    </SelectImageModalItemContentStyled>
                                </SelectImageModalItemStyled>
                            ))}
                        </SelectImageModalListBodyStyled>
                    )}
                </SelectImageModalListWrapperStyled>
                <SelectImageModalPreviewStyled>
                    {selectedImage ? <img src={selectedImage.url} alt={selectedImage.originalName} /> : <span>No image has been selected yet</span>}
                </SelectImageModalPreviewStyled>
            </SelectImageModalStyled>
            <SelectImageModalFooterStyled>
                {pagination && pagination?.totalItems > IMAGE_MODAL_PER_PAGE ? (
                    <SelectImageModalFooterPaginationStyled>
                        <Pagination
                            count={pagination.totalPages}
                            disabled={listLoading}
                            page={pagination.currentPage}
                            onChange={(e, page) => handlePaginationModelChange(page)}
                        />
                    </SelectImageModalFooterPaginationStyled>
                ) : null}
                <SelectImageModalFooterButtonsStyled>
                    <Button disabled={!selectedImage} onClick={handleSelectImageForm} variant="contained">
                        Select Image
                    </Button>
                </SelectImageModalFooterButtonsStyled>
            </SelectImageModalFooterStyled>
        </>
    );
};

export default SelectImageModal;
