import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { TextFieldSchema } from "@/types/form-generator-schema-type";
import DirectionProvider from "@/Providers/DirectionProvider";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    className?: string;
    errorMessage: FieldError;
    props?: TextFieldSchema["props"];
}
const FormTextarea = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => {
    return (
        <FormControl fullWidth variant="outlined" className={className} margin="normal">
            <DirectionProvider dir={(props.dir as "rtl" | "ltr") || "ltr"}>
                <TextField multiline rows={6} {...field} {...props} fullWidth variant="outlined" placeholder={placeholder} label={label} />
                {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
            </DirectionProvider>
        </FormControl>
    );
};

export default FormTextarea;
