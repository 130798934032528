import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Card, CardContent, Container, Grid, IconButton, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import FormGenerator from "@/components/kit/FormGenerator";
import { createHomeUiValidationSchema } from "./validation";
import { homeSchema } from "./schema";
import { IHomeCreateFormType } from "./form-type";
import { IParams } from "@/types/params";
import { IHomeListResponse, Section } from "@/api/types/home";
import { homeService } from "@/api/services/home";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { CATEGORY } from "@/enums/home-page-ui";
import { handleError } from "@/utils/fetch-error";
import AddIcon from "@mui/icons-material/Add";
import MemoizedDraggableList, { ItemType } from "@/components/kit/DnD";
import SectionCard from "../Section/Card";
import { useUiSectionStore } from "@/context/ui-section";

const HomeUiForm: React.FC = () => {
    const { id } = useParams<IParams>();
    const isEditing = Boolean(id);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(isEditing);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [homePageUi, setHomePageUi] = useState<IHomeListResponse>();
    const [sampleData, setSampleData] = useState<ItemType<Section>[]>([]);
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const listAccess = checkPermissionAccess(PERMISSIONS.HOME_LIST);
    const { uiSections, resetUiSections, setAllUiSections } = useUiSectionStore();

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        reset,
    } = useForm<IHomeCreateFormType>({
        defaultValues: generateDefaultValueFromFormSchema(homeSchema),
        mode: "all",
        resolver: yupResolver(createHomeUiValidationSchema),
    });

    useEffect(() => {
        if (isEditing) {
            fetchEditData();
        }
    }, [isEditing]);

    const fetchEditData = async () => {
        try {
            setLoading(true);
            const response = await homeService.show(Number(id));
            const { name, category, sections } = response.data.data;
            setHomePageUi(response.data.data);
            reset({
                category: category === CATEGORY.ENTERTAINMENT ? 2 : 1,
                name,
            });
            if (uiSections.length < 1) {
                setAllUiSections(sections || []);
            }
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async (formValues: IHomeCreateFormType) => {
        try {
            setSubmitLoading(true);
            const payload = {
                name: formValues.name,
                category: formValues.category === 2 ? CATEGORY.ENTERTAINMENT : CATEGORY.EDUCATION,
                sections: uiSections,
            };
            if (isEditing) {
                await homeService.update(Number(id), payload);
            } else {
                await homeService.create(payload);
            }
            enqueueSnackbar(`${isEditing ? "App UI updated" : "New App UI created"} successfully`, { variant: "success" });
            resetUiSections();
            navigate(ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ROOT.ABSOLUTE);
        } catch (err) {
            handleError(err);
        } finally {
            setSubmitLoading(false);
        }
    };

    useEffect(() => {
        if (uiSections.length > 0) {
            setSampleData(
                uiSections.map((section, index) => ({
                    id: `${index}`,
                    content: <SectionCard key={index} section={section} index={index} handleDeleteSection={handleDeleteSection} />,
                    data: section,
                }))
            );
        } else {
            setSampleData([]);
        }
    }, [uiSections]);

    const handleOrderChange = async (newOrder: ItemType<Section>[]) => {
        const updatedSections = newOrder.map((item) => item.data);
        setAllUiSections(updatedSections);
        const payload = {
            name: homePageUi?.name,
            category: homePageUi?.category,
            sections: updatedSections,
        };
        if (isEditing) {
            await homeService.update(Number(id), payload);
        }
        enqueueSnackbar(`${"App UI Section updated"} successfully`, { variant: "success" });
    };

    const handleDeleteSection = async (index: number) => {
        const updatedSections = uiSections.filter((_, i) => i !== index);
        setAllUiSections(updatedSections);
        const payload = {
            name: homePageUi?.name,
            category: homePageUi?.category,
            sections: updatedSections,
        };
        if (isEditing) {
            await homeService.update(Number(id), payload);
        }
        enqueueSnackbar(`${isEditing ? "App UI section deleted" : "New App UI created"} successfully`, { variant: "success" });
    };

    const footer = (
        <Stack spacing={2} direction="row" paddingBottom={10}>
            <LoadingButton type="submit" disabled={!isValid || submitLoading} loading={submitLoading} variant="contained">
                {isEditing ? "Update" : "Create"} Home UI
            </LoadingButton>
            <Button type="button" onClick={() => navigate(-1)}>
                Cancel
            </Button>
        </Stack>
    );

    return (
        <>
            <DashboardHeader
                title={`${isEditing ? "Update" : "Create New"} Home page UI`}
                titleSuffix={
                    listAccess && (
                        <Button component={Link} to={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ROOT.ABSOLUTE}>
                            Back To Home List
                        </Button>
                    )
                }
            />
            <DashboardContent>
                <Grid container spacing={2} sx={{ padding: "16px", fontWeight: "bold" }} px={10}>
                    <Grid item xs={4} textAlign={"center"}>
                        <Typography>Module</Typography>
                    </Grid>
                    <Grid item xs={4} textAlign={"center"}>
                        <Typography>Tag</Typography>
                    </Grid>
                    <Grid item xs={4} textAlign={"center"}>
                        <Typography>Actions</Typography>
                    </Grid>
                </Grid>
                <MemoizedDraggableList initialItems={sampleData} onOrderChanged={handleOrderChange} />
                <Container sx={{ textAlign: "center" }}>
                    <Card>
                        <CardContent sx={{ paddingBottom: "12px !important" }}>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ cursor: "pointer" }}
                                mb={0}
                                component={Link}
                                to={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ADD_NEW_SECTION.ROOT.ABSOLUTE}
                            >
                                <AddIcon />
                            </Typography>
                        </CardContent>
                    </Card>
                </Container>
                <FormGenerator schema={homeSchema} control={control} errors={errors} onSubmit={handleSubmit(onSubmit)} loading={loading} footer={footer} />
            </DashboardContent>
        </>
    );
};

export default HomeUiForm;
