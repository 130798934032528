import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import { IAxiosResponse } from "@/types/axios-response";
import { ITableParams, ITableResponse } from "@/types/table";
import { ICustomerBlockPayload, ICustomerListResponse, ICustomerUpdatePayload } from "@/api/types/customer";

export const customerService = {
    list(params?: ITableParams) {
        return AxiosInstance.post<IAxiosResponse<ITableResponse<ICustomerListResponse>>>(
            API_ROUTES.CUSTOMER.LIST,
            params
        );
    },
    update(id: number, data: ICustomerUpdatePayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.CUSTOMER.UPDATE(id), data);
    },
    block(id: number, data: ICustomerBlockPayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.CUSTOMER.BLOCK(id), data);
    },
    show(id: number) {
        return AxiosInstance.get<IAxiosResponse<ICustomerListResponse>>(API_ROUTES.CUSTOMER.SHOW(id));
    },
};
