import { arabicCharactersRegex } from "@/constants/regexes";
import { stripHTMLTags } from "@/constants/strip-html-tags";
import { object, string } from "yup";

export const createStaticValidationSchema = object().shape({
    en_title: string().required("English title is required"),
    ar_title: string().required("Arabic title is required").matches(arabicCharactersRegex, "Arabic title must only accept Arabic characters"),
    slug: string().required("Slug is required"),
    en_html_content: string().required("English title is required"),
    ar_html_content: string()
        .required("Arabic content is required")
        .test("is-valid-arabic", "Arabic content must only accept Arabic characters", function (value) {
            const strippedContent = stripHTMLTags(value || "");
            return arabicCharactersRegex.test(strippedContent);
        }),
});
