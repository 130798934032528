import { useState } from "react";
import { IconButton, TextField } from "@mui/material";
import { Check } from "@mui/icons-material";

export default ({ onSubmit, value }: { value: string; onSubmit: (input: string) => void }) => {
    const [input, setInput] = useState(value || "");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value);

    const handleSubmit = () => onSubmit(input);

    return (
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <TextField value={input} onChange={handleChange} />
            <IconButton onClick={handleSubmit}>
                <Check />
            </IconButton>
        </div>
    );
};
