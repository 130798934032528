import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import { IAxiosResponse } from "@/types/axios-response";
import { ITableParams, ITableResponse } from "@/types/table";
import { IGenreCreatePayload, IGenreListResponse, IGenreUpdatePayload } from "@/api/types/genre";

export const genreService = {
    list(params?: ITableParams) {
        return AxiosInstance.post<IAxiosResponse<ITableResponse<IGenreListResponse>>>(API_ROUTES.GENRE.LIST, params);
    },
    create(data: IGenreCreatePayload) {
        return AxiosInstance.post<IAxiosResponse>(API_ROUTES.GENRE.CREATE, data);
    },
    update(id: number, data: IGenreUpdatePayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.GENRE.UPDATE(id), data);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse<ITableResponse<IGenreListResponse>>>(API_ROUTES.GENRE.DELETE(id));
    },
    show(id: number) {
        return AxiosInstance.get<IAxiosResponse<IGenreListResponse>>(API_ROUTES.GENRE.SHOW(id));
    },
};
