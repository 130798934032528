import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import { IAxiosResponse } from "@/types/axios-response";
import { ITableParams, ITableResponse } from "@/types/table";
import {
    IVideoCreatePayload,
    IVideoDeleteResponse,
    IVideoListResponse,
    IVideoSessionTokenResponse,
    IVideoShowResponse,
    IVideoTokenResponse,
    IVideoUpdatePayload,
} from "@/api/types/video";
import { VIDEO_API_ROUTES } from "@/constants/video-api-routes";
import { videoBaseUrl } from "@/constants/api-base-urls";

export const videoService = {
    list(params?: ITableParams) {
        return AxiosInstance.post<IAxiosResponse<ITableResponse<IVideoListResponse>>>(API_ROUTES.VIDEO.LIST, params);
    },
    create(data: IVideoCreatePayload) {
        return AxiosInstance.post<IAxiosResponse>(API_ROUTES.VIDEO.CREATE, data);
    },
    update(id: number, data: IVideoUpdatePayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.VIDEO.UPDATE(id), data);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse<IVideoDeleteResponse>>(API_ROUTES.VIDEO.DELETE(id));
    },
    show(id: number) {
        return AxiosInstance.get<IAxiosResponse<IVideoShowResponse>>(API_ROUTES.VIDEO.SHOW(id));
    },
    token() {
        return AxiosInstance.get<IAxiosResponse<IVideoTokenResponse>>(API_ROUTES.VIDEO.TOKEN);
    },
    webhooks() {
        return AxiosInstance.post<IAxiosResponse>(API_ROUTES.VIDEO.WEBHOOKS);
    },
    sessionToken(videoId: string, privateToken: string) {
        return AxiosInstance.get<IAxiosResponse<IVideoSessionTokenResponse>>(
            VIDEO_API_ROUTES.SESSION_TOKEN(videoId, privateToken),
            {
                baseURL: videoBaseUrl,
                headers: { Authorization: "" },
            }
        );
    },
};
