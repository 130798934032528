import { Stack, styled } from "@mui/material";

export const EpisodesStyled = styled("section")(
    ({ theme }) => `
    padding: ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)};
    background-color: rgba(15, 98, 243, 0.05);
    border-radius: ${theme.spacing(2)};
    margin-top: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};
`
);
export const EpisodeHeaderStyled = styled(Stack)(
    ({ theme }) => `
    width: 100%;
    padding-right: ${theme.spacing(3)};
    button {
        opacity: 0;
        visibility: hidden;
        transition: 0.2s;
        &:first-of-type {
            margin-left: auto;
        }
    }
    &:hover {
        button {
            opacity: 1;
            visibility: visible
        }
    }
`
);
