import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

export type EpisodeFormSchemaFields =
    | "mainVideo"
    | "thumbnailPortraitId"
    | "thumbnailLandscapeId"
    | "en_title"
    | "ar_title"
    | "en_description"
    | "ar_description"
    | "sharing_state";

export type IEpisodeFormSchema = Record<EpisodeFormSchemaFields, IFormGeneratorSchemaType>;

export const seasonSchema: IEpisodeFormSchema = {
    mainVideo: {
        label: "Select Video",
        placeholder: "Video file",
        type: FORM_INPUTS.SELECT_VIDEO,
        props: {
            accept: "video/*",
        },
    },
    thumbnailPortraitId: {
        label: "Thumbnail Portrait",
        placeholder: "(Max Size: 3Mb)",
        type: FORM_INPUTS.SELECT_IMAGE,
        props: {
            required: true,
            accept: "image/*",
        },
    },
    thumbnailLandscapeId: {
        label: "Thumbnail Landscape",
        placeholder: "(Max Size: 3Mb)",
        type: FORM_INPUTS.SELECT_IMAGE,
        props: {
            required: true,
            accept: "image/*",
        },
    },
    en_title: {
        label: "English Content Name",
        placeholder: "English Content Name",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
        },
    },
    ar_title: {
        label: "Arabic Content Name",
        placeholder: "Arabic Content Name",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
            dir: "rtl",
        },
    },
    en_description: {
        label: "English Description",
        placeholder: "English Description",
        type: FORM_INPUTS.TEXTAREA,
        props: {
            required: true,
        },
    },
    ar_description: {
        label: "Arabic Description",
        placeholder: "Arabic Description",
        type: FORM_INPUTS.TEXTAREA,
        props: {
            required: true,
            dir: "rtl",
        },
    },
    sharing_state: {
        label: "Sharing State",
        placeholder: "Sharing State",
        type: FORM_INPUTS.RADIO,
        defaultValue: 1,
        data: [
            { value: 1, label: "Draft" },
            { value: 2, label: "Publish" },
        ],
        props: {
            required: true,
        },
    },
};
