import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";

import AddEpisodeForm from "../../../../forms/addEpisodes";

import { IContentCreatePayload, IContentUpdatePayload } from "@/api/types/content";
import { CONTENT_TYPE } from "@/enums/content-type";
import { useCreateContent, useUpdateContent } from "@/api/hooks/useContents";
import { ICreateEpisodeForm } from "@/pages/ContentManagement/Content/forms/addEpisodes/types";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";

interface IEpisodeModal {
    open: boolean;
    refetch?: () => void;
    onClose: () => void;
    episodeId: number | null;
    parentId: number;
    maxNumber: number;
}

export default function EpisodeModal({ open, onClose, refetch, episodeId, parentId, maxNumber }: IEpisodeModal) {
    const { mutate: createContent } = useCreateContent();
    const { mutate: updateContent } = useUpdateContent();
    const { enqueueSnackbar } = useSnackbar();

    const { checkPermissionAccess } = useCheckPermissionAccess();
    const createAccess = checkPermissionAccess(PERMISSIONS.CONTENT_CREATE);

    const onSubmit = (formVal: ICreateEpisodeForm) => {
        if (!createAccess) {
            enqueueSnackbar("You don't have access for creating or editing a Session");

            return;
        }

        const body: IContentCreatePayload | IContentUpdatePayload = {
            ml_name: {
                en: formVal.en_title,
                ar: formVal.ar_title,
            },
            ml_description: {
                en: formVal.en_description,
                ar: formVal.ar_description,
            },
            mainVideo: formVal.mainVideo,
            thumbnailLandscapeId: formVal.thumbnailLandscapeId,
            thumbnailPortraitId: formVal.thumbnailPortraitId,
            typeId: CONTENT_TYPE.EPISODE,
            parentId,
            status: String(formVal.sharing_state),
            episode: formVal.autoId && maxNumber ? Number(maxNumber + 1) : formVal.episode,
        };

        if (episodeId) {
            updateContent(
                { id: Number(episodeId), body },
                {
                    onSuccess: () => {
                        if (refetch) refetch();
                    },
                }
            );
        } else {
            body.mainVideo = formVal.mainVideo;
            createContent(body, {
                onSuccess: () => {
                    if (refetch) refetch();
                    onClose();
                },
            });
        }
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitle> Episode Form </DialogTitle>
            <DialogContent>
                <AddEpisodeForm episodeId={episodeId} onSubmit={onSubmit} />
            </DialogContent>
        </Dialog>
    );
}
