import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";

import Validation from "./validation";
import { ICreateEpisodeForm } from "./types";
import AddEpisodesStyle from "./style";

import { useContentById } from "@/api/hooks/useContents";
import FormSelectVideo from "@/components/kit/FormGenerator/FormSelectVideo";
import FormSelectImage from "@/components/kit/FormGenerator/FormSelectImage";
import FormTextarea from "@/components/kit/FormGenerator/FormTextarea";
import FormText from "@/components/kit/FormGenerator/FormText";
import FormCheckbox from "@/components/kit/FormGenerator/FormCheckbox";
import FormRadio from "@/components/kit/FormGenerator/FormRadio";

const defaultValues = {
    en_title: "",
    ar_title: "",
    en_description: "",
    ar_description: "",
    mainVideo: 0,
    thumbnailLandscapeId: 0,
    thumbnailPortraitId: 0,
    autoId: false,
    episode: 0,
    sharing_state: 0,
};

interface IAddEpisode {
    episodeId: number | null;
    // eslint-disable-next-line no-unused-vars
    onSubmit: (formData: ICreateEpisodeForm) => void;
}

export default function AddEpisodes({ episodeId, onSubmit }: IAddEpisode) {
    const { data: EpisodeData } = useContentById(Number(episodeId));

    const content = EpisodeData?.data.data;

    const {
        control,
        reset,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<ICreateEpisodeForm>({
        defaultValues,
        mode: "all",
        resolver: yupResolver(Validation),
    });

    // update form data in Edit mode
    useEffect(() => {
        // make sure genre & tags & ageRange are fetched before
        if (content) {
            const { ml_description, mainVideo, thumbnailLandscape, thumbnailPortrait, ml_name, episode, status } = content;

            reset({
                episode: episode,
                mainVideo: mainVideo?.id,
                thumbnailLandscapeId: thumbnailLandscape?.id,
                thumbnailPortraitId: thumbnailPortrait?.id,
                en_title: ml_name.en,
                ar_title: ml_name.ar,
                ar_description: ml_description.ar,
                en_description: ml_description.en,
                sharing_state: status === "Draft" ? 1 : 2,
            });
        }
    }, [content, reset]);

    useEffect(() => {
        if (!episodeId) reset(defaultValues);
    }, [reset, episodeId]);

    const autoId = watch("autoId");

    return (
        <AddEpisodesStyle className="add-episode-form-wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    key="autoId"
                    control={control}
                    name="autoId"
                    render={({ field }) => (
                        <FormCheckbox
                            className="auto-id field"
                            errorMessage={errors["autoId"] as FieldError}
                            field={{ ...field }}
                            label="Automatically add episode number"
                            placeholder="Automatically add episode number"
                        />
                    )}
                />
                <Controller
                    key="episode"
                    control={control}
                    disabled={autoId}
                    name="episode"
                    render={({ field }) => (
                        <FormText
                            className="textarea"
                            errorMessage={errors["episode"] as FieldError}
                            field={{ ...field }}
                            label="Enter Episode No"
                            placeholder="Enter Episode No"
                            props={{ type: "number" }}
                        />
                    )}
                />
                <div className="content">
                    <div className="col left-sec">
                        <div className="portrait-box">
                            <Controller
                                key="thumbnailPortraitId"
                                control={control}
                                name="thumbnailPortraitId"
                                render={({ field }) => (
                                    <FormSelectImage
                                        className="portrait-thumb"
                                        errorMessage={errors["thumbnailPortraitId"] as FieldError}
                                        field={{ ...field }}
                                        label="Thumbnail Portrait *"
                                        placeholder="Image File"
                                        props={{ previewImage: content?.thumbnailPortrait?.url }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="col right-sec">
                        <div className="landscape-box">
                            <Controller
                                key="thumbnailLandscapeId"
                                control={control}
                                name="thumbnailLandscapeId"
                                render={({ field }) => (
                                    <FormSelectImage
                                        className="landscape-thumb"
                                        errorMessage={errors["thumbnailLandscapeId"] as FieldError}
                                        field={{ ...field }}
                                        label="Thumbnail Landscape *"
                                        placeholder="Image File"
                                        props={{ previewImage: content?.thumbnailLandscape?.url }}
                                    />
                                )}
                            />
                            <div className="video-section">
                                <Controller
                                    key="mainVideo"
                                    control={control}
                                    name="mainVideo"
                                    render={({ field }) => (
                                        <FormSelectVideo
                                            className="select-video"
                                            errorMessage={errors["mainVideo"] as FieldError}
                                            field={{ ...field }}
                                            label="Select Video"
                                            placeholder="Video File"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content grid">
                    <Controller
                        key="en_title"
                        control={control}
                        name="en_title"
                        render={({ field }) => (
                            <FormText
                                className="en_title field"
                                errorMessage={errors["en_title"] as FieldError}
                                field={{ ...field }}
                                label="English Content Name *"
                                placeholder="Video File"
                            />
                        )}
                    />
                    <Controller
                        key="ar_title"
                        control={control}
                        name="ar_title"
                        render={({ field }) => (
                            <FormText
                                className="ar_title field"
                                errorMessage={errors["ar_title"] as FieldError}
                                field={{ ...field }}
                                label="Arabic Content Name *"
                                placeholder="Video File"
                            />
                        )}
                    />
                    <Controller
                        key="en_description"
                        control={control}
                        name="en_description"
                        render={({ field }) => (
                            <FormTextarea
                                className="en_description field"
                                errorMessage={errors["en_description"] as FieldError}
                                field={{ ...field }}
                                label="English Description"
                                placeholder="Video File"
                            />
                        )}
                    />
                    <Controller
                        key="ar_description"
                        control={control}
                        name="ar_description"
                        render={({ field }) => (
                            <FormTextarea
                                className="ar_description field"
                                errorMessage={errors["ar_description"] as FieldError}
                                field={{ ...field }}
                                label="Arabic Description"
                                placeholder="Enter Text"
                            />
                        )}
                    />
                    <Controller
                        key="sharing_state"
                        control={control}
                        name="sharing_state"
                        render={({ field }) => (
                            <FormRadio
                                className="sharing_state"
                                data={[
                                    { value: 1, label: "Draft" },
                                    { value: 2, label: "Publish" },
                                ]}
                                errorMessage={errors["sharing_state"] as FieldError}
                                field={{ ...field }}
                                label="Sharing State"
                                placeholder="Select Option"
                            />
                        )}
                    />
                </div>

                <Box className="actions" sx={{ display: "flex", justifyContent: "flex-start", marginTop: "10px" }}>
                    <LoadingButton className="loading-btn" type="submit" variant="contained">
                        {episodeId ? "Update" : "Create"} Content
                    </LoadingButton>
                    {/* <LoadingButton className="loading-btn" sx={{ marginInline: "10px" }} variant="contained" onClick={clear}>
                        Clear
                    </LoadingButton> */}
                </Box>
            </form>
        </AddEpisodesStyle>
    );
}
