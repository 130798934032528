import Table from "@/components/kit/Table";
import { ITableParams } from "@/types/table";
import { genreListColumnSchema } from "./column-schema";
import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import { Button } from "@mui/material";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { getAxiosError } from "@/utils/get-axios-error";
import { enqueueSnackbar } from "notistack";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { genreService } from "@/api/services/genre";
import { IGenreListResponse } from "@/api/types/genre";
import { PERMISSIONS } from "@/enums/permissions";
import SortAndFilters from "@/components/app/SortAndFilters";
import { filterListNames } from "@/constants/filter-list-names";
import { IGetListBody } from "@/api/types/base-data";
import { ILazyParams } from "@/types/filters";
import { DEFAULT_PAGE_SIZE } from "@/constants/paginations";

const GenreList: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState<IGetListBody>();
    const [genres, setGenres] = useState<any>();
    const [lazyParams, setLazyParams] = useState<ILazyParams>({
        filter: {},
        first: 0,
        page: 0,
        rows: DEFAULT_PAGE_SIZE,
        sort: {},
    });
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const createAccess = checkPermissionAccess(PERMISSIONS.GENRE_CREATE);
    const editAccess = checkPermissionAccess(PERMISSIONS.GENRE_UPDATE);
    const deleteAccess = checkPermissionAccess(PERMISSIONS.GENRE_DELETE);

    const fetchGenres = useCallback(
        async (queryParams?: ITableParams) => {
            try {
                setLoading(true);
                const body = {
                    ...filters,
                    skip: lazyParams.page * DEFAULT_PAGE_SIZE,
                    take: lazyParams.rows,
                    ...queryParams,
                };
                if (lazyParams.sort && JSON.stringify(lazyParams.sort) !== "{}") {
                    body.sort = lazyParams.sort;
                }
                const result = await genreService.list(body);
                setGenres(result);
            } catch (err) {
                enqueueSnackbar("Failed to load genres", { variant: "error" });
            } finally {
                setLoading(false);
            }
        },
        [filters, lazyParams]
    );

    useEffect(() => {
        fetchGenres();
    }, [fetchGenres, filters, lazyParams]);

    const onSortAndFiltersChanged = (data: IGetListBody) => {
        setFilters(data);
        setLazyParams({
            ...lazyParams,
            page: 0,
        });
    };

    const suffixCols = (tableData: IGenreListResponse[], setTableData: Dispatch<SetStateAction<IGenreListResponse[]>>): GridColDef[] => {
        const handleDelete = (id: number) => {
            const handleAction = async () => {
                try {
                    setLoading(true);
                    await genreService.delete(id);
                    enqueueSnackbar(`Genre deleted successfully`, { variant: "success" });
                    setTableData((prev) => (prev ? prev.filter((item) => item.id !== id) : prev));
                } catch (err) {
                    const error = getAxiosError(err);
                    const message = error?.meta.message || "Server Error";
                    enqueueSnackbar(message, { variant: "error" });
                } finally {
                    setLoading(false);
                }
            };

            modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                header: "Delete Confirmation",
                text: "Are you sure to delete this genre?",
                handleAction,
            });
        };

        return [
            {
                field: "actions",
                type: "actions",
                headerName: "Actions",
                filterable: false,
                hideable: false,
                resizable: false,
                flex: 1,
                align: "center",
                sortable: false,
                getActions: (params: GridRowParams<IGenreListResponse>) => {
                    return [
                        ...(editAccess
                            ? [
                                  <Link to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.GENRES.UPDATE.EDIT_BY_ID(params.row.id).ABSOLUTE}>
                                      <GridActionsCellItem icon={<EditIcon color="action" />} label="Edit" color="inherit" />
                                  </Link>,
                              ]
                            : []),
                        ...(deleteAccess
                            ? [
                                  <GridActionsCellItem
                                      icon={<DeleteIcon color="action" />}
                                      label="Delete"
                                      onClick={() => handleDelete(params.row.id)}
                                      color="inherit"
                                  />,
                              ]
                            : []),
                    ];
                },
            },
        ];
    };

    return (
        <>
            <DashboardHeader
                title="Genre List"
                titleSuffix={
                    createAccess ? (
                        <Button component={Link} to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.GENRES.ADD_NEW.ROOT.ABSOLUTE} color="primary" variant="contained">
                            Add New Genre
                        </Button>
                    ) : null
                }
            />
            <SortAndFilters listName={filterListNames.GENRES} onChange={onSortAndFiltersChanged} />
            <DashboardContent>
                <Table
                    service={genres || []}
                    columnsSchema={genreListColumnSchema}
                    suffixCols={editAccess || deleteAccess ? suffixCols : undefined}
                    loading={loading}
                    showSearch={false}
                    setLazyParams={setLazyParams}
                    lazyParams={lazyParams}
                />
            </DashboardContent>
        </>
    );
};

export default GenreList;
