import { Routes as RoutesWrapper, Route, Navigate } from "react-router-dom";
import NotFound from "src/pages/NotFound";
import Forbidden from "src/pages/Forbidden";
import AuthLayout from "src/layouts/AuthLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

import ROUTE_CONSTANTS from "../constants/route-constants";

import Login from "@/pages/auth/Login";
import Dashboard from "@/pages/Dashboard";
import UserManagement from "@/pages/UserManagement";
import UserList from "@/pages/UserManagement/User";
import UserForm from "@/pages/UserManagement/User/AddUpdate";
import SetPassword from "@/pages/auth/SetPassword";
import ForgetPassword from "@/pages/auth/ForgetPassword";
import ContentManagement from "@/pages/ContentManagement";
import TagList from "@/pages/ContentManagement/Tag";
import TagForm from "@/pages/ContentManagement/Tag/AddUpdate";
import GenreList from "@/pages/ContentManagement/Genre";
import GenreForm from "@/pages/ContentManagement/Genre/AddUpdate";
import ContentList from "@/pages/ContentManagement/Content";
// import ContentForm from "@/pages/ContentManagement/Content/AddUpdate";
import AddMovieFrom from "@/pages/ContentManagement/Content/AddMovie"; /// ------------- HERE
import AddSerial from "@/pages/ContentManagement/Content/AddSerial";
import Publisher from "@/pages/ContentManagement/publisher";
import ContentShow from "@/pages/ContentManagement/Content/Show";
// import { CONTENT_TYPE } from "@/enums/content-type";
import CustomerList from "@/pages/UserManagement/Cusomer";
import CustomerForm from "@/pages/UserManagement/Cusomer/AddUpdate";
import CustomerShow from "@/pages/UserManagement/Cusomer/Show";
import MediaManagement from "@/pages/MediaManagement";
import VideoList from "@/pages/MediaManagement/Video";
import VideoForm from "@/pages/MediaManagement/Video/AddUpdate";
import VideoShow from "@/pages/MediaManagement/Video/Show";
import { PERMISSIONS } from "@/enums/permissions";
import ImageList from "@/pages/MediaManagement/Image";
import ImageForm from "@/pages/MediaManagement/Image/AddUpdate";
import AppUiManagement from "@/pages/AppUIMAnagement";
import HomePage from "@/pages/AppUIMAnagement/HomePage";
import HomeUiForm from "@/pages/AppUIMAnagement/HomePage/AddUpdate";
import HomeUiSection from "@/pages/AppUIMAnagement/HomePage/Section";
import AvatarList from "@/pages/MediaManagement/Avatar";
import AvatarForm from "@/pages/MediaManagement/Avatar/AddUpdate";
import StaticList from "@/pages/Settings/Static";
import StaticForm from "@/pages/Settings/Static/AddUpdate";
import Settings from "@/pages/Settings";
import Countries from "@/pages/Countries";
import ShowCountries from "@/pages/Countries/show";
// import AddCountries from "@/pages/Countries/add";
// import UpdateCountries from "@/pages/Countries/update";
import CountryDetail from "@/pages/Countries/detail";
import ProtectedRoute, { withProtectedRoutes } from "@/Routes/ProtectedRoute";

const UserManagementProtected = withProtectedRoutes(UserManagement, [PERMISSIONS.USER_LIST]);
const UserCountriesProtected = withProtectedRoutes(Countries, [PERMISSIONS.COUNTRIES_LIST]);

const Routes: React.FC = () => {
    return (
        <RoutesWrapper>
            <Route element={<AuthLayout />} path={ROUTE_CONSTANTS.AUTH.ROOT.RELATIVE}>
                <Route element={<Navigate replace to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE} />} path="" />
                <Route element={<Login />} path={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} />
                <Route element={<SetPassword />} path={ROUTE_CONSTANTS.AUTH.SET_PASSWORD.RELATIVE} />
                <Route element={<ForgetPassword />} path={ROUTE_CONSTANTS.AUTH.FORGET_PASSWORD.RELATIVE} />
            </Route>

            <Route element={<DashboardLayout />} path={ROUTE_CONSTANTS.ROOT.RELATIVE}>
                <Route element={<Navigate replace to={ROUTE_CONSTANTS.DASHBOARD.ROOT.RELATIVE} />} path="" />
                <Route element={<Dashboard />} path={ROUTE_CONSTANTS.DASHBOARD.ROOT.RELATIVE} />

                {/* Countries */}
                <Route element={<UserCountriesProtected />} path={ROUTE_CONSTANTS.COUNTRIES.ROOT.RELATIVE}>
                    <Route element={<ShowCountries />} path={ROUTE_CONSTANTS.COUNTRIES.ROOT.ABSOLUTE} />
                    <Route element={<CountryDetail />} path={ROUTE_CONSTANTS.COUNTRIES.CONTENT.DETAILS.ROUTE.ABSOLUTE} />
                </Route>

                {/* User Management */}
                <Route element={<UserManagementProtected />} path={ROUTE_CONSTANTS.USER_MANAGEMENT.ROOT.RELATIVE}>
                    <Route element={<Navigate replace to={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ROOT.ABSOLUTE} />} path="" />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.USER_LIST]}>
                                <UserList />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.USER_UPDATE]}>
                                <UserForm />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.UPDATE.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.USER_CREATE]}>
                                <UserForm />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ADD_NEW.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.CUSTOMER_LIST]}>
                                <CustomerList />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.CUSTOMER_UPDATE]}>
                                <CustomerForm />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.UPDATE.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.CUSTOMER_DETAIL]}>
                                <CustomerShow />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.SHOW.ROOT.RELATIVE}
                    />
                </Route>

                {/* Content Management */}
                <Route
                    element={
                        <ProtectedRoute permissions={[PERMISSIONS.CONTENT_LIST]}>
                            <ContentManagement />
                        </ProtectedRoute>
                    }
                    path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.ROOT.RELATIVE}
                >
                    <Route
                        element={<Navigate replace to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ROOT.ABSOLUTE} />}
                        path=""
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.CONTENT_LIST]}>
                                <ContentList />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.CONTENT_CREATE]}>
                                <AddMovieFrom />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ADD_NEW_MOVIE.ROOT.RELATIVE}
                    />

                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.CONTENT_CREATE]}>
                                <AddSerial />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ADD_NEW_SERIES.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.CONTENT_UPDATE]}>
                                <AddSerial />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.UPDATE_SERIES.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.CONTENT_UPDATE]}>
                                <AddMovieFrom />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.UPDATE_MOVIE.ROOT.RELATIVE}
                    />

                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.CONTENT_DETAIL]}>
                                <ContentShow />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.SHOW.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.TAG_LIST]}>
                                <TagList />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.TAGS.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.TAG_UPDATE]}>
                                <TagForm />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.TAGS.UPDATE.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.TAG_CREATE]}>
                                <TagForm />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.TAGS.ADD_NEW.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.GENRE_LIST]}>
                                <GenreList />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.GENRES.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.GENRE_UPDATE]}>
                                <GenreForm />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.GENRES.UPDATE.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.GENRE_CREATE]}>
                                <GenreForm />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.GENRES.ADD_NEW.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.CONTENT_LIST]}>
                                <Publisher />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.SHOW_PUBLISHER.ROOT.RELATIVE}
                    />
                </Route>

                {/* Media Management */}
                <Route
                    element={
                        <ProtectedRoute permissions={[PERMISSIONS.VIDEO_LIST]}>
                            <MediaManagement />
                        </ProtectedRoute>
                    }
                    path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.ROOT.RELATIVE}
                >
                    <Route
                        element={<Navigate replace to={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.ROOT.ABSOLUTE} />}
                        path=""
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.VIDEO_LIST]}>
                                <VideoList />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.VIDEO_UPDATE]}>
                                <VideoForm />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.UPDATE.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.VIDEO_CREATE]}>
                                <VideoForm />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.ADD_NEW.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.VIDEO_DETAIL]}>
                                <VideoShow />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.SHOW.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.IMAGE_LIST]}>
                                <ImageList />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.IMAGES.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.IMAGE_CREATE]}>
                                <ImageForm />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.IMAGES.ADD_NEW.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.IMAGE_LIST]}>
                                <AvatarList />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.AVATARS.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.IMAGE_CREATE]}>
                                <AvatarForm />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.AVATARS.ADD_NEW.ROOT.RELATIVE}
                    />
                </Route>

                {/* UI Management */}
                <Route
                    element={
                        <ProtectedRoute permissions={[PERMISSIONS.HOME_LIST]}>
                            <AppUiManagement />
                        </ProtectedRoute>
                    }
                    path={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.ROOT.RELATIVE}
                >
                    <Route
                        element={<Navigate replace to={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ROOT.ABSOLUTE} />}
                        path=""
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.HOME_LIST]}>
                                <HomePage />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ROOT.ABSOLUTE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.HOME_UPDATE]}>
                                <HomeUiForm />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.UPDATE.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.USER_CREATE]}>
                                <HomeUiForm />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ADD_NEW.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.USER_CREATE]}>
                                <HomeUiSection />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ADD_NEW_SECTION.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.HOME_UPDATE]}>
                                <HomeUiSection />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.UPDATE_SECTION.ROOT.RELATIVE}
                    />
                </Route>

                {/* Settings Management */}
                <Route
                    element={
                        <ProtectedRoute permissions={[PERMISSIONS.STATIC_LIST]}>
                            <Settings />
                        </ProtectedRoute>
                    }
                    path={ROUTE_CONSTANTS.SETTINGS.ROOT.RELATIVE}
                >
                    <Route element={<Navigate replace to={ROUTE_CONSTANTS.SETTINGS.STATIC.ROOT.ABSOLUTE} />} path="" />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.STATIC_LIST]}>
                                <StaticList />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.SETTINGS.STATIC.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.STATIC_UPDATE]}>
                                <StaticForm />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.SETTINGS.STATIC.UPDATE.ROOT.RELATIVE}
                    />
                    <Route
                        element={
                            <ProtectedRoute permissions={[PERMISSIONS.STATIC_CREATE]}>
                                <StaticForm />
                            </ProtectedRoute>
                        }
                        path={ROUTE_CONSTANTS.SETTINGS.STATIC.ADD_NEW.ROOT.RELATIVE}
                    />
                </Route>
            </Route>
            <Route element={<NotFound />} path={ROUTE_CONSTANTS.ROOT_STAR.ABSOLUTE} />
            <Route element={<NotFound />} path={ROUTE_CONSTANTS.NOT_FOUND.RELATIVE} />
            <Route element={<Forbidden />} path={ROUTE_CONSTANTS.FORBIDDEN.RELATIVE} />
        </RoutesWrapper>
    );
};

export default Routes;
