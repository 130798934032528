import * as yup from "yup";

export default yup.object().shape({
    mainVideo: yup.number().required("you must choose a video"),
    thumbnailPortraitId: yup.number().required("you must choose a Portrait Image"),
    thumbnailLandscapeId: yup.number().required("you must choose a Landscape Image"),
    en_title: yup
        .string()
        .min(2, "English title must be at most 2 characters")
        .max(80, "English title must be at least 80 characters")
        .required("English title is required"),
    ar_title: yup
        .string()
        .required("Arabic title is required")
        .min(2, "Arabic title must be at most 2 characters")
        .max(80, "Arabic title must be at least 80 characters"),
    slug: yup.string(),
    en_description: yup.string().max(600, "English description must be at least 600 characters").required("required"),
    ar_description: yup.string().max(600, "Arabic description must be at least 600 characters").required("required"),
    sharing_state: yup.number().required("required"),
    category: yup.number().required("required"),
    genre: yup
        .array()
        .of(yup.number().required("required"))
        .min(1, "You must choose at least one item")
        .required("required"),
    tag: yup.array().of(yup.number().required("required")).min(1, "You must choose at least one item").required("required"),
    age_range: yup.number().required("required"),
    publisherId: yup.number().required("required"),

    imdb: yup.string(),
    duration: yup.string(),
    intro_duration: yup.string(),
    subtitle: yup.string(),
    trailerVideo: yup.number(),
});
