import PersonOutline from "@mui/icons-material/PersonOutline";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import { Settings } from "@mui/icons-material";

import { IMenuData } from "./types";

import { PERMISSIONS } from "@/enums/permissions";
import ROUTE_CONSTANTS from "@/constants/route-constants";
const menuData: IMenuData[] = [
    {
        id: 1,
        title: "User Management",
        icon: <PersonOutline />,
        href: ROUTE_CONSTANTS.USER_MANAGEMENT.ROOT.ABSOLUTE,
        permission: [PERMISSIONS.USER_LIST, PERMISSIONS.CUSTOMER_LIST],
        children: [
            {
                id: 11,
                title: "Users",
                href: ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ROOT.ABSOLUTE,
                permission: [PERMISSIONS.USER_LIST],
            },
            {
                id: 12,
                title: "Customers",
                href: ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.ROOT.ABSOLUTE,
                permission: [PERMISSIONS.CUSTOMER_LIST],
            },
        ],
    },

    {
        id: 2,
        title: "Content Management",
        icon: <NewspaperIcon />,
        href: ROUTE_CONSTANTS.CONTENT_MANAGEMENT.ROOT.ABSOLUTE,
        permission: [PERMISSIONS.CONTENT_LIST, PERMISSIONS.TAG_LIST, PERMISSIONS.GENRE_LIST],
        children: [
            {
                id: 21,
                title: "Contents",
                href: ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ROOT.ABSOLUTE,
                permission: [PERMISSIONS.CONTENT_LIST],
            },
            {
                id: 22,
                title: "Tags",
                href: ROUTE_CONSTANTS.CONTENT_MANAGEMENT.TAGS.ROOT.ABSOLUTE,
                permission: [PERMISSIONS.TAG_LIST],
            },
            {
                id: 23,
                title: "Genres",
                href: ROUTE_CONSTANTS.CONTENT_MANAGEMENT.GENRES.ROOT.ABSOLUTE,
                permission: [PERMISSIONS.GENRE_LIST],
            },
            {
                id: 24,
                title: "Publisher",
                href: ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.SHOW_PUBLISHER.ROOT.ABSOLUTE,
                permission: [PERMISSIONS.GENRE_LIST],
            },
        ],
    },
    {
        id: 3,
        title: "Media Management",
        icon: <OndemandVideoIcon />,
        href: ROUTE_CONSTANTS.MEDIA_MANAGEMENT.ROOT.ABSOLUTE,
        permission: [PERMISSIONS.VIDEO_LIST],
        children: [
            {
                id: 31,
                title: "Videos",
                href: ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.ROOT.ABSOLUTE,
                permission: [PERMISSIONS.VIDEO_LIST],
            },
            {
                id: 32,
                title: "Images",
                href: ROUTE_CONSTANTS.MEDIA_MANAGEMENT.IMAGES.ROOT.ABSOLUTE,
                permission: [PERMISSIONS.IMAGE_LIST],
            },
            {
                id: 33,
                title: "Avatars",
                href: ROUTE_CONSTANTS.MEDIA_MANAGEMENT.AVATARS.ROOT.ABSOLUTE,
                permission: [PERMISSIONS.AVATAR_LIST],
            },
        ],
    },
    {
        id: 4,
        title: "App UI Management",
        icon: <AppShortcutIcon />,
        href: ROUTE_CONSTANTS.APP_UI_MANAGEMENT.ROOT.ABSOLUTE,
        permission: [PERMISSIONS.HOME_LIST],
        children: [
            {
                id: 41,
                title: "Home Page",
                href: ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ROOT.ABSOLUTE,
                permission: [PERMISSIONS.HOME_LIST],
            },
        ],
    },
    {
        id: 5,
        title: "Settings",
        icon: <Settings />,
        href: ROUTE_CONSTANTS.SETTINGS.ROOT.ABSOLUTE,
        permission: [PERMISSIONS.STATIC_LIST],
        children: [
            {
                id: 51,
                title: "Static Page",
                href: ROUTE_CONSTANTS.SETTINGS.STATIC.ROOT.ABSOLUTE,
                permission: [PERMISSIONS.STATIC_LIST],
            },
            {
                id: 52,
                title: "Country & Currency",
                href: ROUTE_CONSTANTS.COUNTRIES.ROOT.ABSOLUTE,
                permission: [PERMISSIONS.STATIC_LIST],
            },
        ],
    },
];

export default menuData;
