import { customerService } from "@/api/services/customer";
import Table from "@/components/kit/Table";
import { ITableParams } from "@/types/table";
import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import { customerListColumnSchema } from "./column-schema";

const service = async (queryParams?: ITableParams) => customerService.list(queryParams);

const CustomerList: React.FC = () => {
    return (
        <>
            <DashboardHeader title="Customer List" />
            <DashboardContent>
                <Table columnsSchema={customerListColumnSchema} service={service} />
            </DashboardContent>
        </>
    );
};

export default CustomerList;
