import { Stack, styled } from "@mui/material";

export const SeasonsStyled = styled("section")(
    ({ theme }) => `
    padding: ${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)};
    background-color: rgba(15, 98, 243, 0.1);
    border-radius: ${theme.spacing(3)};
    max-width: 1000px;
    margin: ${theme.spacing(3)} auto;
    `
);
export const SeasonHeaderStyled = styled(Stack)(
    ({ theme }) => `
    width: 100%;
    padding-right: ${theme.spacing(3)};

    button {
        opacity: 0;
        visibility: hidden;
        transition: 0.2s;
        &:first-of-type {
            margin-left: auto;
        }
    }
    &:hover {
        button {
            opacity: 1;
            visibility: visible
        }
    }
`
);
