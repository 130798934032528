import FormGenerator from "@/components/kit/FormGenerator";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { useForm } from "react-hook-form";
import { seasonSchema } from "./schema";
import { ISeasonCreateFormType } from "./form-type";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { getAxiosError } from "@/utils/get-axios-error";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { contentService } from "@/api/services/content";
import { slugify } from "@/utils/slugify";
import { IContentListResponse } from "@/api/types/content";

interface Props {
    parentId: number;
    id?: number;
    seasonCount: number | undefined;
    onClose: () => void;
    setData: React.Dispatch<React.SetStateAction<IContentListResponse[] | undefined>>;
}

const SeasonForm: React.FC<Props> = ({ parentId, id, seasonCount, onClose, setData }) => {
    const isEditingRule = Boolean(id);
    const [seasonOrderNumber, setSeasonOrderNumber] = useState<number>(seasonCount ? seasonCount + 1 : 1);
    const [loading, setLoading] = useState<boolean>(isEditingRule);
    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        reset,
    } = useForm<ISeasonCreateFormType>({
        defaultValues: generateDefaultValueFromFormSchema(seasonSchema),
        mode: "all",
        // resolver: yupResolver(createContentValidationSchema),
    });

    const onSubmitForm = async (_val: ISeasonCreateFormType) => {
        try {
            setLoading(true);
            const payload = {
                parentId,
                slug: slugify(_val.en_title),
                ml_name: {
                    en: _val.en_title,
                    ar: _val.ar_title,
                },
                ml_description: {
                    en: _val.en_description,
                    ar: _val.ar_description,
                },
                typeId: 3,
                status: "2",
                season: seasonOrderNumber,
            };
            if (isEditingRule) {
                const response = await contentService.updateSeason(Number(id), payload);
                setData((prev) => (prev ? prev.map((item) => (item.id === Number(id) ? response.data.data : item)) : undefined));
            } else {
                const response = await contentService.createSeason(payload);
                setData((prev) => (prev ? [...prev, response.data.data] : undefined));
            }
            enqueueSnackbar(`${isEditingRule ? "Season updated" : "New season created"} successfully`, { variant: "success" });
            onClose();
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    const getEditData = async () => {
        try {
            setLoading(true);
            const response = await contentService.show(Number(id));
            const { ml_description, ml_name, season } = response.data.data;
            reset({
                ar_title: ml_name.ar,
                ar_description: ml_description.ar,
                en_title: ml_name.en,
                en_description: ml_description.en,
            });
            setSeasonOrderNumber(season!);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    const footer = (
        <Stack spacing={2} direction={"row"}>
            <LoadingButton type="submit" disabled={!isValid || loading} loading={loading} variant="contained">
                {isEditingRule ? "Update" : "Create"} Season
            </LoadingButton>
            <Button type="button" onClick={onClose}>
                Cancel
            </Button>
        </Stack>
    );

    useEffect(() => {
        isEditingRule && getEditData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditingRule]);

    return <FormGenerator schema={seasonSchema} control={control} errors={errors} onSubmit={handleSubmit(onSubmitForm)} loading={loading} footer={footer} />;
};

export default SeasonForm;
