import { Box, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

import MakeSerialStyle from "./style";
import SerialCard from "./serialCard";
import AddCreateSessionModal from "./formModal";

import { useContentList } from "@/api/hooks/useContents";

export default function AddSessions() {
    const { id: serialIdString } = useParams();
    const [edit, setEdit] = useState<number | null>(null);
    const [open, setOpen] = useState(false);

    const serialId = Number(serialIdString);
    const { contentList, reFetch } = useContentList(serialId);

    const maxSessionNumber = contentList.sort(function (a, b) {
        if (Number(a.id) < Number(b.id)) return -1;
        if (Number(a.id) > Number(b.id)) return 1;

        return 0;
    });

    const maxNumber = maxSessionNumber[maxSessionNumber.length - 1];

    const handleEdit = (id: number) => {
        setEdit(id);
    };
    const handleClickOpen = () => setOpen(true);

    const handleClose = () => {
        if (edit) setEdit(null);
        setOpen(false);
    };

    const handleSuccess = () => {
        reFetch();
        setEdit(null);
    };

    return (
        <MakeSerialStyle>
            <Box className="page-header">
                <LoadingButton className="episode-btn" type="submit" variant="contained" onClick={handleClickOpen}>
                    Create Session
                </LoadingButton>
            </Box>
            <Divider />
            <Box className="page-content">
                <Box className="serial-list">
                    {contentList.map((serial) => {
                        return <SerialCard key={serial.id} id={serial.id} onEdit={handleEdit} onSuccess={reFetch} />;
                    })}
                </Box>
            </Box>
            {serialId && (
                <AddCreateSessionModal
                    maxNumber={maxNumber?.season}
                    open={open || !!edit}
                    parentId={serialId}
                    sessionId={edit}
                    onClose={handleClose}
                    onSettled={() => setEdit(null)}
                    onSuccess={handleSuccess}
                />
            )}
        </MakeSerialStyle>
    );
}
