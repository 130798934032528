import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useEffect, useState } from "react";

import {
    DeleteIconStyled,
    FormSelectVideoStyled,
    FormSelectVideoUploadStyled,
    FormSelectVideoWrapperStyled,
} from "./styled";

import { SelectVideoFieldSchema } from "@/types/form-generator-schema-type";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { IVideoShowResponse } from "@/api/types/video";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    className?: string;
    errorMessage: FieldError;
    props?: SelectVideoFieldSchema["props"];
}
const FormSelectVideo = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => {
    const { previewImage, previewName } = props || {};

    const [imageSource, setImageSource] = useState(previewImage || "");
    const [fileName, setFileName] = useState<string | undefined>(previewName || "");

    useEffect(() => {
        if (props.previewImage) setImageSource(props.previewImage);
    }, [props]);

    const handleRemove = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.stopPropagation();
        setFileName("");
        field.onChange(undefined);
        setImageSource("");
    };

    const handleOnChange = (videoItem: IVideoShowResponse) => {
        setFileName(videoItem.title);
        setImageSource(videoItem.thumbnail?.url || "");
        field.onChange(videoItem.id);
    };

    const openVideoModal = () => {
        modalActions.addModal(MODAL_TYPES.SELECT_VIDEO_MODAL, {
            header: "ُSelect Video Modal",
            width: "1100px",
            preview: true,
            onChange: handleOnChange,
        });
    };

    return (
        <>
            <FormSelectVideoStyled className={className}>
                <label>
                    {label} {props?.required ? "*" : ""}
                </label>
                <FormSelectVideoWrapperStyled onClick={openVideoModal}>
                    {imageSource.length ? <img alt={label} src={imageSource} /> : null}
                    {field.value ? <DeleteIconStyled onClick={handleRemove} /> : null}
                    {!fileName?.length ? (
                        <FormSelectVideoUploadStyled>
                            <CloudUploadIcon />
                            <span>Choose Video ...</span>
                            {placeholder ? <small>{placeholder}</small> : null}
                        </FormSelectVideoUploadStyled>
                    ) : null}
                </FormSelectVideoWrapperStyled>
                {fileName ? <span>{fileName?.length ? fileName : null}</span> : null}
            </FormSelectVideoStyled>
            {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
        </>
    );
};

export default FormSelectVideo;
