import { useMutation } from "@tanstack/react-query";
import { useState, useEffect } from "react";

import { ITableParams } from "@/types/table";
import { errorCallback } from "@/api/hooks/error";
import { genreService } from "@/api/services/genre";
import { IGenreListResponse } from "@/api/types/genre";

export function useGenre() {
    const [genres, setGenres] = useState<IGenreListResponse[]>();

    const { mutate, ...restProps } = useMutation({
        mutationFn: (body: ITableParams) => genreService.list(body),
        onSuccess: (result) => setGenres(result.data.data.items),
        onError: errorCallback,
    });

    useEffect(() => mutate({} as ITableParams, {}), [mutate]);

    return { genres, mutate, ...restProps };
}
