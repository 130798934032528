import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import FormGenerator from "@/components/kit/FormGenerator";
import { UserFormSchemaFields, userSchema } from "./schema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createUserValidationSchema } from "./validation";
import { IUserCreateFormType } from "./form-type";
import { IUserCreatePayload } from "@/api/types/user";
import { userService } from "@/api/services/user";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAxiosError } from "@/utils/get-axios-error";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { enqueueSnackbar } from "notistack";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { IParams } from "@/types/params";
import { IFormGeneratorGeneralSchemaType } from "@/types/form-generator-schema-type";
import { USER_ROLE } from "@/enums/user-role";
import { useUser } from "@/context/authContext";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";

const UserForm: React.FC = () => {
    const { id } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const user = useUser();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(isEditingRule);
    const [formFieldsSchema, setFormFieldsSchema] = useState<Record<UserFormSchemaFields, IFormGeneratorGeneralSchemaType>>(userSchema);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const listAccess = checkPermissionAccess(PERMISSIONS.USER_LIST);

    const isAdministrator = user?.role === USER_ROLE.ADMINISTRATOR;

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        reset,
    } = useForm<IUserCreateFormType>({
        defaultValues: generateDefaultValueFromFormSchema(formFieldsSchema),
        mode: "all",
        resolver: yupResolver(createUserValidationSchema),
    });

    const getEditData = async () => {
        try {
            setLoading(true);
            const response = await userService.show(Number(id));
            setFormFieldsSchema((prev) => ({
                ...prev,
                email: { ...prev.email, props: prev.email.props ? { ...prev.email.props, disabled: true } : { disabled: true } },
                ...(!isAdministrator ? { role: { ...prev.role, props: prev.role.props ? { ...prev.role.props, disabled: true } : { disabled: true } } } : {}),
            }));
            const { email, lastName, firstName, role, phoneNumber } = response.data.data;
            reset({ email, lastName, firstName, role, phoneNumber });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async (_val: IUserCreatePayload) => {
        try {
            setSubmitLoading(true);
            if (isEditingRule) {
                const { email, ...payload } = _val;
                await userService.update(Number(id), payload);
            } else {
                await userService.create(_val);
            }
            enqueueSnackbar(`${isEditingRule ? "User updated" : "New user created"} successfully`, { variant: "success" });
            navigate(ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ROOT.ABSOLUTE);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setSubmitLoading(false);
        }
    };

    useEffect(() => {
        isEditingRule && getEditData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditingRule]);

    const footer = (
        <Stack spacing={2} direction={"row"} paddingBottom={10}>
            <LoadingButton type="submit" disabled={!isValid || submitLoading} loading={submitLoading} variant="contained">
                {isEditingRule ? "Update" : "Create"} User
            </LoadingButton>
            <Button type="button" onClick={() => navigate(-1)}>
                Cancel
            </Button>
        </Stack>
    );

    return (
        <>
            <DashboardHeader
                title={`${isEditingRule ? "Update" : "Create New"} User`}
                {...(listAccess
                    ? {
                          titleSuffix: (
                              <Button component={Link} to={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ROOT.ABSOLUTE}>
                                  Back To User List
                              </Button>
                          ),
                      }
                    : undefined)}
            />
            <DashboardContent>
                <FormGenerator
                    schema={formFieldsSchema}
                    control={control}
                    errors={errors}
                    onSubmit={handleSubmit(onSubmit)}
                    loading={loading}
                    footer={footer}
                />
            </DashboardContent>
        </>
    );
};

export default UserForm;
