import dayjs from "dayjs";
import { useState } from "react";
import { BorderColor, Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useUpdateCountry } from "@/api/hooks/useCountries";
import { TableOfContentStyles } from "./style";
import { ICountry } from "@/api/types/countries";
import RateInput from "./rateInput";

export default function TableOfContent({ result }: { result: ICountry }) {
    const [editMode, setEditModeState] = useState<boolean>(false);
    const { mutate: updateCountry } = useUpdateCountry();

    const handleEditMode = () => setEditModeState((prevState) => !prevState);

    const handleUpdateCountry = (rateInput: string) => {
        const newDate = {
            ...result,
            rate: Number(rateInput),
        };
        updateCountry({ id: Number(result.id), body: newDate }, { onSuccess: () => setEditModeState(false) });
    };

    const {
        code2Char,
        code3Char,
        createdAt,
        currency,
        currencyIso,
        decimal,
        dialCode,
        flag,
        flagUrl,
        gmtOffset,
        isActive,
        rate,
        timeZone,
        updatedAt,
    } = result;

    return (
        <TableOfContentStyles className="table-of-content">
            <div>
                <label>createdAt</label>
                <span>{dayjs(createdAt).format("YYYY-MM-DD HH:mm")}</span>
            </div>
            <div>
                <label>updatedAt</label>
                <span>{dayjs(updatedAt).format("YYYY-MM-DD HH:mm")}</span>
            </div>
            <div>
                <label>code2Char</label>
                <span>{code2Char}</span>
            </div>
            <div>
                <label>code3Char</label>
                <span>{code3Char}</span>
            </div>
            <div>
                <label>decimal</label>
                <span>{decimal}</span>
            </div>
            <div>
                <label>currency</label>
                <span>{currency}</span>
            </div>
            <div>
                <label>Rate</label>
                <span>
                    {!editMode ? (
                        <span
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <span style={{ marginInline: "3px" }}>{rate}</span>
                            <IconButton onClick={handleEditMode}>{editMode ? <Close /> : <BorderColor />}</IconButton>
                        </span>
                    ) : (
                        <RateInput value={String(rate)} onSubmit={handleUpdateCountry} />
                    )}
                </span>
            </div>
            <div>
                <label>currencyIso</label>
                <span>{currencyIso}</span>
            </div>
            <div>
                <label>dialCode</label>
                <span>{dialCode}</span>
            </div>
            <div>
                <label>flag</label>
                <span>{flag}</span>
            </div>
            <div>
                <label>gmtOffset</label>
                <span>{gmtOffset}</span>
            </div>
            <div>
                <label>Image</label>
                <span>
                    <img src={flagUrl} />
                </span>
            </div>
            <div>
                <label>isActive</label>
                <span>{isActive ? " Active " : "Not Active"}</span>
            </div>
            <div>
                <label>timeZone</label>
                <span>{timeZone}</span>
            </div>
        </TableOfContentStyles>
    );
}
