import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

export type SeasonFormSchemaFields = "en_title" | "ar_title" | "en_description" | "ar_description";

export type ISeasonFormSchema = Record<SeasonFormSchemaFields, IFormGeneratorSchemaType>;

export const seasonSchema: ISeasonFormSchema = {
    en_title: {
        label: "English Content Name",
        placeholder: "English Content Name",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
        },
    },
    ar_title: {
        label: "Arabic Content Name",
        placeholder: "Arabic Content Name",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
            dir: "rtl",
        },
    },
    en_description: {
        label: "English Description",
        placeholder: "English Description",
        type: FORM_INPUTS.TEXTAREA,
        props: {
            required: true,
        },
    },
    ar_description: {
        label: "Arabic Description",
        placeholder: "Arabic Description",
        type: FORM_INPUTS.TEXTAREA,
        props: {
            required: true,
            dir: "rtl",
        },
    },
};
