import { Accordion, AccordionDetails, AccordionSummary, Button, Modal, Stack, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useEffect, useState } from "react";
import EpisodeForm from "./form";
import { IContentListResponse } from "@/api/types/content";
import InitLoading from "@/components/app/Loading/InitLoading";
import { contentService } from "@/api/services/content";
import { getAxiosError } from "@/utils/get-axios-error";
import { enqueueSnackbar } from "notistack";
import { EpisodeHeaderStyled, EpisodesStyled } from "./styled";
import { ModalContentStyled, ModalHeaderStyled, ModalWrapperStyled } from "@/components/app/Modal/styled";
import CloseIcon from "@mui/icons-material/Close";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";

interface Props {
    seasonId: number;
    seasonNo: number;
}
const Episodes: React.FC<Props> = ({ seasonId, seasonNo }) => {
    const [showForm, setShowForm] = useState(false);
    const [editSeasonId, setEditSeasonId] = useState<number>();
    const [episodeListLoading, setEpisodeListLoading] = useState(false);
    const [episodeData, setEpisodeData] = useState<IContentListResponse[]>();

    const { checkPermissionAccess } = useCheckPermissionAccess();
    const createAccess = checkPermissionAccess(PERMISSIONS.CONTENT_CREATE);
    const editAccess = checkPermissionAccess(PERMISSIONS.CONTENT_UPDATE);
    const deleteAccess = checkPermissionAccess(PERMISSIONS.CONTENT_DELETE);

    const fetchByParent = async () => {
        try {
            const response = await contentService.list({ filter: { parentId: seasonId } });
            return response.data.data;
        } catch (err) {
            const error = getAxiosError(err);
            enqueueSnackbar(error.meta?.message || "", { variant: "error" });
        }
    };

    const fetchEpisodes = async () => {
        setEpisodeListLoading(true);
        const response = await fetchByParent();
        setEpisodeData(response?.items);
        setEpisodeListLoading(false);
    };

    useEffect(() => {
        fetchEpisodes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpenModal = () => setShowForm(true);

    const handleCloseModal = () => {
        setShowForm(false);
        setEditSeasonId(undefined);
    };

    const handleEditMode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, editId: number) => {
        e.stopPropagation();
        setEditSeasonId(editId);
        setShowForm(true);
    };

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, deleteId: number) => {
        e.stopPropagation();
        const handleAction = async () => {
            try {
                await contentService.delete(deleteId);
                enqueueSnackbar(`Episode deleted successfully`, { variant: "success" });
                setEpisodeData((prev) => (prev ? prev.filter((item) => item.id !== deleteId) : undefined));
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.meta.message || "Server Error";
                enqueueSnackbar(message, { variant: "error" });
            }
        };

        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: "Delete Confirmation",
            text: "Are you sure to delete this episode?",
            handleAction,
        });
    };

    return (
        <>
            <EpisodesStyled>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} marginBottom={2}>
                    <h3>Episodes</h3>
                    {createAccess ? (
                        <Button variant="contained" color={"primary"} onClick={handleOpenModal}>
                            Add New Episode
                        </Button>
                    ) : null}
                </Stack>
                {episodeListLoading ? (
                    <InitLoading height="100px" />
                ) : episodeData?.length ? (
                    episodeData.map((item, index) => (
                        <Accordion key={item.id}>
                            <AccordionSummary expandIcon={<ArrowDownwardIcon />} aria-controls={`panel-content-${item.id}`} id={`panel-header-${item.id}`}>
                                <EpisodeHeaderStyled direction={"row"} spacing={1} alignItems={"center"}>
                                    <Typography>
                                        <b>Episode {index + 1}</b>
                                        {item.ml_name.en.length ? `: ${item.ml_name.en}` : ""}
                                    </Typography>
                                    {deleteAccess ? (
                                        <Button variant="contained" color="error" size="small" onClick={(e) => handleDelete(e, item.id)}>
                                            Delete
                                        </Button>
                                    ) : null}
                                    {editAccess ? (
                                        <Button variant="contained" size="small" onClick={(e) => handleEditMode(e, item.id)}>
                                            Edit
                                        </Button>
                                    ) : null}
                                </EpisodeHeaderStyled>
                            </AccordionSummary>
                            <AccordionDetails>{item.ml_description.en}</AccordionDetails>
                        </Accordion>
                    ))
                ) : (
                    <Stack direction={"row"} justifyContent={"center"} padding={3} alignItems={"center"}>
                        <span>No Episodes Data!</span>
                    </Stack>
                )}
            </EpisodesStyled>
            <Modal open={showForm} onClose={handleCloseModal}>
                <ModalWrapperStyled>
                    <ModalHeaderStyled>
                        <h5>{editSeasonId ? "Edit episode" : "Create new episode"}</h5>
                        <CloseIcon onClick={handleCloseModal} />
                    </ModalHeaderStyled>
                    <ModalContentStyled>
                        <EpisodeForm
                            onClose={handleCloseModal}
                            episodeCount={episodeData?.length}
                            parentId={seasonId}
                            id={editSeasonId}
                            seasonNo={seasonNo}
                            setData={setEpisodeData}
                        />
                    </ModalContentStyled>
                </ModalWrapperStyled>
            </Modal>
        </>
    );
};

export default Episodes;
