import { Accordion, AccordionDetails, AccordionSummary, Button, Modal, Stack, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useEffect, useState } from "react";
import SeasonForm from "./form";
import { contentService } from "@/api/services/content";
import { IContentListResponse } from "@/api/types/content";
import { getAxiosError } from "@/utils/get-axios-error";
import { enqueueSnackbar } from "notistack";
import InitLoading from "@/components/app/Loading/InitLoading";
import { useParams } from "react-router-dom";
import { IParams } from "@/types/params";
import Episodes from "../Episode";
import { SeasonHeaderStyled, SeasonsStyled } from "./styled";
import { ModalContentStyled, ModalHeaderStyled, ModalWrapperStyled } from "@/components/app/Modal/styled";
import CloseIcon from "@mui/icons-material/Close";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";

const Seasons = () => {
    const { id } = useParams<IParams>();
    const parentId = Number(id);
    const [showForm, setShowForm] = useState(false);
    const [editSeasonId, setEditSeasonId] = useState<number>();

    const [seasonListLoading, setSeasonListLoading] = useState(false);
    const [seasonData, setSeasonData] = useState<IContentListResponse[]>();

    const { checkPermissionAccess } = useCheckPermissionAccess();
    const createAccess = checkPermissionAccess(PERMISSIONS.CONTENT_CREATE);
    const editAccess = checkPermissionAccess(PERMISSIONS.CONTENT_UPDATE);
    const deleteAccess = checkPermissionAccess(PERMISSIONS.CONTENT_DELETE);

    const fetchByParent = async (parentId: number) => {
        try {
            const response = await contentService.list({ filter: { parentId } });
            setSeasonData(response.data.data.items);
        } catch (err) {
            const error = getAxiosError(err);
            enqueueSnackbar(error.meta?.message || "", { variant: "error" });
        }
    };

    const fetchSeasons = async () => {
        setSeasonListLoading(true);
        fetchByParent(parentId);
        setSeasonListLoading(false);
    };

    useEffect(() => {
        fetchSeasons();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpenModal = () => setShowForm(true);
    const handleCloseModal = () => {
        setShowForm(false);
        setEditSeasonId(undefined);
    };
    const handleEditMode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, editId: number) => {
        e.stopPropagation();
        setEditSeasonId(editId);
        setShowForm(true);
    };

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, deleteId: number) => {
        e.stopPropagation();
        const handleAction = async () => {
            try {
                await contentService.delete(deleteId);
                enqueueSnackbar(`Season deleted successfully`, { variant: "success" });
                setSeasonData((prev) => (prev ? prev.filter((item) => item.id !== deleteId) : undefined));
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.meta.message || "Server Error";
                enqueueSnackbar(message, { variant: "error" });
            }
        };

        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: "Delete Confirmation",
            text: "Are you sure to delete this season?",
            handleAction,
        });
    };

    return (
        <>
            <SeasonsStyled>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} marginBottom={2}>
                    <h3>Seasons</h3>
                    {createAccess ? (
                        <Button variant="contained" color={"primary"} onClick={handleOpenModal}>
                            Add New Season
                        </Button>
                    ) : null}
                </Stack>

                {seasonListLoading ? (
                    <InitLoading height="100px" />
                ) : seasonData?.length ? (
                    <Stack>
                        {seasonData.map((item, index) => (
                            <Accordion key={item.id}>
                                <AccordionSummary expandIcon={<ArrowDownwardIcon />} aria-controls={`panel-content-${item.id}`} id={`panel-header-${item.id}`}>
                                    <SeasonHeaderStyled direction={"row"} spacing={1} alignItems={"center"}>
                                        <Typography>
                                            <b>Season {index + 1}</b>
                                            {item.ml_name.en.length ? `: ${item.ml_name.en}` : ""}
                                        </Typography>
                                        {deleteAccess ? (
                                            <Button variant="contained" color="error" size="small" onClick={(e) => handleDelete(e, item.id)}>
                                                Delete
                                            </Button>
                                        ) : null}
                                        {editAccess ? (
                                            <Button variant="contained" size="small" onClick={(e) => handleEditMode(e, item.id)}>
                                                Edit
                                            </Button>
                                        ) : null}
                                    </SeasonHeaderStyled>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Episodes seasonId={item.id} seasonNo={index + 1} />
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Stack>
                ) : (
                    <Stack direction={"row"} justifyContent={"center"} padding={3} alignItems={"center"}>
                        <span>No Seasons Data!</span>
                    </Stack>
                )}
            </SeasonsStyled>
            <Modal open={showForm} onClose={handleCloseModal}>
                <ModalWrapperStyled>
                    <ModalHeaderStyled>
                        <h5>{editSeasonId ? "Edit season" : "Create new season"}</h5>
                        <CloseIcon onClick={handleCloseModal} />
                    </ModalHeaderStyled>
                    <ModalContentStyled>
                        <SeasonForm onClose={handleCloseModal} seasonCount={seasonData?.length} parentId={parentId} id={editSeasonId} setData={setSeasonData} />
                    </ModalContentStyled>
                </ModalWrapperStyled>
            </Modal>
        </>
    );
};

export default Seasons;
