import React, { memo, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import { Container, DraggableItem, DraggableListStyled } from "./style";

export interface ItemType<T> {
    id: string;
    content: React.ReactNode;
    data: T;
}

interface DraggableListProps<T> {
    initialItems: ItemType<T>[];
    onOrderChanged?: (newOrder: ItemType<T>[]) => void;
}

const DraggableList = <T,>(props: DraggableListProps<T>): JSX.Element => {
    const { initialItems, onOrderChanged } = props;
    const [items, setItems] = useState<ItemType<T>[]>(initialItems);

    const handleOnDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const newItems = Array.from(items);
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);

        setItems(newItems);

        if (onOrderChanged) {
            onOrderChanged(newItems);
        }
    };

    useEffect(() => {
        setItems(initialItems);
    }, [initialItems]);

    return (
        <Container>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppable-list">
                    {(provided) => (
                        <DraggableListStyled className="draggable-list" {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map(({ id, content }, index) => (
                                <Draggable key={id} draggableId={id} index={index}>
                                    {(provided, snapshot) => (
                                        <DraggableItem
                                            className={`draggable-item ${snapshot.isDragging ? "dragging" : ""}`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            {content}
                                        </DraggableItem>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </DraggableListStyled>
                    )}
                </Droppable>
            </DragDropContext>
        </Container>
    );
};

const MemoizedDraggableList = memo(DraggableList) as <T>(props: DraggableListProps<T>) => JSX.Element;

export default MemoizedDraggableList;
