import { IModalProps } from "@/types/modals";
import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";
import { ReactNode, useState } from "react";

export interface ConfirmationModalProps {
    text: ReactNode;
    handleAction?: () => Promise<void> | void;
    autoClose?: boolean;
    actionButtonText?: string;
    cancelButtonText?: string;
    disableCancelButton?: boolean;
    disableSubmitButton?: boolean;
    actionButtonColor?: "primary" | "secondary" | "error" | "success" | "inherit" | "info" | "warning";
}

const ConfirmationModal: React.FC<IModalProps<ConfirmationModalProps>> = ({
    data: {
        text,
        handleAction,
        actionButtonText,
        actionButtonColor,
        cancelButtonText,
        disableSubmitButton = false,
        disableCancelButton = false,
        autoClose = true,
    },
    closeModal,
}) => {
    const [loading, setLoading] = useState<boolean>();

    const handleClick = async () => {
        setLoading(true);
        handleAction && (await handleAction());
        setLoading(false);
        autoClose && closeModal();
    };

    return (
        <>
            <p>{text}</p>
            <Stack spacing={2} direction={"row"}>
                {!disableSubmitButton && (
                    <LoadingButton
                        disabled={loading}
                        color={actionButtonColor || "error"}
                        variant="contained"
                        loading={loading}
                        onClick={handleAction ? handleClick : () => closeModal()}
                    >
                        {actionButtonText || "Yes, I'm Sure"}
                    </LoadingButton>
                )}
                {!disableCancelButton && (
                    <Button color="primary" onClick={() => closeModal()}>
                        {cancelButtonText || "No, Canceled"}
                    </Button>
                )}
            </Stack>
        </>
    );
};

export default ConfirmationModal;
