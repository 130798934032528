import { useGetCountryById } from "@/api/hooks/useCountries";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";

import TableOfContent from "./table";
import DetailsStyle from "./style";

export default function Details() {
    const { id: countryId } = useParams();
    const { data, isFetching, isPending } = useGetCountryById(Number(countryId));

    const result = data?.data;

    if (!result || isPending || isFetching) return <p>Loading ...</p>;

    return (
        <DetailsStyle>
            <Typography className="title">
                Details of
                <i>
                    <span style={{ marginInline: "5px" }}>{result.title}</span>
                </i>
                Country
            </Typography>
            <TableOfContent result={result} />
        </DetailsStyle>
    );
}
