import type {
    ICountry,
    IGetCountriesListBody,
    IGetCountriesMiniListBody,
    IPutCountryByIdBody,
    IAddCountryByIdBody,
} from "@/api/types/countries";
import type { FilterBody } from "@/api/types";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";

import {
    GetCountryById,
    UpdateCountryById,
    DeleteCountry,
    CreateCountry,
    GetCountries,
    GetCountriesMiniList,
} from "@/api/services/countries";
import { errorCallback } from "@/api/hooks/error";

export function useGetCountryById(id: number) {
    return useQuery({
        queryKey: ["country", id],
        queryFn: () => GetCountryById(id),
    });
}

// ? these APIs are using the POST method for the most part.
// ? so having a cache in TanStackQuery is little bit a JOKE

export function useGetCountries() {
    const [countries, setCountries] = useState<ICountry[]>([]);

    const { mutate, ...restProps } = useMutation({
        mutationFn: (body: IGetCountriesListBody) => GetCountries(body),
        onError: errorCallback,
        onSuccess: (result) => {
            const { items } = result.data;

            setCountries(items);
        },
    });

    useEffect(() => mutate({} as FilterBody, {}), [mutate]);

    return { countries, mutate, ...restProps };
}

export function useUpdateCountry() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ id, body }: { id: number, body: IPutCountryByIdBody }) => UpdateCountryById(id, body),
        onSuccess: (data, { id }) => {
            queryClient.setQueryData(["country", id], data);
        },
    });
}

export function useDeleteCountry() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => DeleteCountry(id),
        onSuccess: (_, id) => {
            queryClient.invalidateQueries({ queryKey: ["country", id] });
        },
    });
}

export function useCreateCountry() {
    return useMutation({
        mutationFn: (body: IAddCountryByIdBody) => CreateCountry(body),
    });
}

export function useGetMiniList() {
    return useMutation({
        mutationFn: (body: IGetCountriesMiniListBody) => GetCountriesMiniList(body),
    });
}
