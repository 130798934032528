import { styled } from "@mui/material";

export const GridCardStyled = styled("div")(
    ({ theme }) => `
    color: #fff;
    a {
        color: #fff;
    }
`
);
export const GridCardImageStyled = styled("div")(
    ({ theme }) => `
    display: flex;
    position: relative;
    border-radius: ${theme.spacing(1)};
    height: 160px;
    width: 160px
    background: ${theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"};
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: ${theme.spacing(1)};
    }
    div {
        opacity: 0;
        visibility: hidden;
        transition: 0.15s
    }
    &:hover {
        div {
            opacity: 1;
            visibility: visible;
        }
    }
`
);

export const GridCardTitleStyled = styled("span")(
    ({ theme }) => `
   margin-top: ${theme.spacing(1)};
   display: block;
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
   font-size: 12px;
   color: ${theme.palette.mode === "dark" ? "#fff" : "#000"};
`
);

export const GridCardActionsStyled = styled("div")(
    ({ theme }) => `
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    padding: ${theme.spacing(1)} ${theme.spacing(1)};
    border-radius: 0 0 ${theme.spacing(1)} ${theme.spacing(1)};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${theme.spacing(1.5)};
    a {
        display: inline-flex;
    }
    svg {
        cursor: pointer;
    }
`
);
