import Table from "@/components/kit/Table";
import { ITableParams } from "@/types/table";
import { contentListColumnSchema } from "./column-schema";
import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import { Button, Menu, MenuItem } from "@mui/material";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { Dispatch, MouseEvent, SetStateAction, useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import { getAxiosError } from "@/utils/get-axios-error";
import { enqueueSnackbar } from "notistack";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { contentService } from "@/api/services/content";
import { IContentListResponse } from "@/api/types/content";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { PERMISSIONS } from "@/enums/permissions";
import { filterListNames } from "@/constants/filter-list-names";
import SortAndFilters from "@/components/app/SortAndFilters";
import { IGetListBody } from "@/api/types/base-data";
import { DEFAULT_PAGE_SIZE } from "@/constants/paginations";
import InitLoading from "@/components/app/Loading/InitLoading";
import { ILazyParams } from "@/types/filters";

const ContentList: React.FC = () => {
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const createAccess = checkPermissionAccess(PERMISSIONS.CONTENT_CREATE);
    const deleteAccess = checkPermissionAccess(PERMISSIONS.CONTENT_DELETE);
    const showAccess = checkPermissionAccess(PERMISSIONS.CONTENT_DETAIL);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filters, setFilters] = useState<IGetListBody>();
    const [contents, setContents] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [lazyParams, setLazyParams] = useState<ILazyParams>({
        filter: {},
        first: 0,
        page: 0,
        rows: DEFAULT_PAGE_SIZE,
        sort: {},
    });

    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    const fetchContents = useCallback(
        async (queryParams?: ITableParams) => {
            try {
                setLoading(true);
                const body = {
                    ...filters,
                    skip: lazyParams.page * DEFAULT_PAGE_SIZE,
                    take: lazyParams.rows,
                    ...queryParams,
                };
                if (lazyParams.sort && JSON.stringify(lazyParams.sort) !== "{}") {
                    body.sort = lazyParams.sort;
                }
                const result = await contentService.list(body);
                setContents(result);
            } catch (err) {
                enqueueSnackbar("Failed to load contents", { variant: "error" });
            } finally {
                setLoading(false);
            }
        },
        [filters, lazyParams]
    );

    useEffect(() => {
        fetchContents();
    }, [fetchContents, filters, lazyParams]);

    const onSortAndFiltersChanged = (data: IGetListBody) => {
        setFilters(data);
        setLazyParams({
            ...lazyParams,
            page: 0,
        });
    };

    const suffixCols = useCallback(
        (tableData: IContentListResponse[], setTableData: Dispatch<SetStateAction<IContentListResponse[]>>): GridColDef[] => {
            const handleDelete = async (id: number) => {
                try {
                    setLoading(true);
                    await contentService.delete(id);
                    enqueueSnackbar(`Content deleted successfully`, { variant: "success" });
                    setTableData((prev) => prev.filter((item) => item.id !== id));
                } catch (err) {
                    const error = getAxiosError(err);
                    enqueueSnackbar(error?.meta?.message || "Server Error", { variant: "error" });
                } finally {
                    setLoading(false);
                }
            };

            return [
                {
                    field: "actions",
                    type: "actions",
                    headerName: "Actions",
                    getActions: (params: GridRowParams<IContentListResponse>) => [
                        ...(showAccess
                            ? [
                                  <Link to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.SHOW.SHOW_BY_ID(params.row.id).ABSOLUTE}>
                                      <GridActionsCellItem icon={<ArrowForwardIcon color="action" />} label="Show" />
                                  </Link>,
                              ]
                            : []),
                        ...(deleteAccess ? [<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => handleDelete(params.row.id)} />] : []),
                    ],
                },
            ];
        },
        [deleteAccess, showAccess]
    );

    return (
        <>
            <DashboardHeader
                title="Content List"
                titleSuffix={
                    createAccess && (
                        <>
                            <Button
                                color="primary"
                                endIcon={<KeyboardArrowDownIcon />}
                                variant="contained"
                                id="basic-button"
                                aria-controls={open ? "basic-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={handleClick}
                            >
                                Add New Content
                            </Button>
                            <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                                <MenuItem component={Link} to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ADD_NEW_MOVIE.ROOT.ABSOLUTE}>
                                    Create Movie
                                </MenuItem>
                                <MenuItem component={Link} to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ADD_NEW_SERIES.ROOT.ABSOLUTE}>
                                    Create Series
                                </MenuItem>
                            </Menu>
                        </>
                    )
                }
            />
            <SortAndFilters listName={filterListNames.CONTENTS} onChange={onSortAndFiltersChanged} />
            <DashboardContent>
                <Table
                    service={contents || []}
                    columnsSchema={contentListColumnSchema}
                    suffixCols={suffixCols}
                    loading={loading}
                    showSearch={false}
                    setLazyParams={setLazyParams}
                    lazyParams={lazyParams}
                />
            </DashboardContent>
        </>
    );
};

export default ContentList;
