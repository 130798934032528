import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { Autocomplete, Box, FormControl, FormHelperText, Grid, Popper, TextField } from "@mui/material";
import { TextFieldSchema } from "@/types/form-generator-schema-type";
import { countries } from "@/constants/countries";
import { useState } from "react";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    className?: string;
    errorMessage: FieldError;
    props?: TextFieldSchema["props"];
}

const FormPhone = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => {
    const [countryCode, setCountryCode] = useState<string | undefined>(field.value ? field.value.substring(0, 4) : undefined);
    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <FormControl fullWidth variant="outlined" className={className} margin="normal">
                    <Autocomplete
                        id={`country-select-${field.name}`}
                        options={countries}
                        autoHighlight
                        value={countries.find((item) => item.phone === field.value?.substring(0, 4))}
                        getOptionLabel={(option) => option.phone}
                        disabled={props.disabled}
                        PopperComponent={(props) => <Popper {...props} placement="bottom-start" style={{ width: "fit-content" }} />}
                        onChange={(_, value) => {
                            setCountryCode(value?.phone);
                        }}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    alt=""
                                />
                                {option.label} ({option.code}) {option.phone}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Country Code"
                                required={props.required}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                    placeholder: "Country Code",
                                }}
                            />
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={8}>
                <FormControl fullWidth variant="outlined" className={className} margin="normal">
                    <TextField
                        {...field}
                        {...props}
                        fullWidth
                        variant="outlined"
                        placeholder={placeholder}
                        label={`${label}${props.required ? " *" : ""}`}
                        value={field.value?.substring(countryCode ? countryCode.length : 0)}
                        onChange={(e) => field.onChange(countryCode ? countryCode + e.target.value : e.target.value)}
                    />
                    {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default FormPhone;
