import { useState } from "react";
import { Paper, Box } from "@mui/material";

import PublisherPageStyle from "./style";
import ShowPublishers from "./show";
import AddPubLishers from "./add";

import { IPublisherListReqBody } from "@/api/types/publisher";
import { usePublisherList } from "@/api/hooks/publisher";

export default function Publisher() {
    const lang = "en";
    const [params] = useState<IPublisherListReqBody>({
        skip: 0,
        take: 10,
        search: "",
        sort: "",
        filter: "",
    });

    const { publishers, mutate } = usePublisherList({ lang, body: params });

    const refetch = () => mutate({ lang, body: params });

    return (
        <PublisherPageStyle>
            <Box className="center">
                <Paper className="paper">
                    <AddPubLishers onCreated={refetch} />
                    <ShowPublishers data={publishers} onDelete={refetch} />
                </Paper>
            </Box>
        </PublisherPageStyle>
    );
}
