import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@mui/material";

import { SelectOptionItem } from "@/types/select-option";
import { RadioFieldSchema } from "@/types/form-generator-schema-type";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    data?: SelectOptionItem[];
    className?: string;
    errorMessage: FieldError;
    props?: RadioFieldSchema["props"];
}

const FormRadio = ({ field, label, placeholder, data, className, errorMessage, props = {} }: IProps) => {
    return (
        <FormControl fullWidth className={className} margin="normal" variant="outlined">
            <FormLabel id={`radio-buttons-group-label-${field.name}`}>
                {label || placeholder} {props.required ? "*" : ""}
            </FormLabel>
            <RadioGroup
                row
                aria-labelledby={`radio-buttons-group-label-${field.name}`}
                defaultValue={field.value}
                name="radio-buttons-group"
            >
                {data?.map((radioItem) => (
                    <FormControlLabel
                        key={radioItem.label}
                        control={
                            <Radio
                                {...field}
                                {...props}
                                checked={field.value === radioItem.value}
                                required={false}
                                onChange={() => field.onChange(radioItem.value)}
                            />
                        }
                        label={radioItem.label}
                    />
                ))}
            </RadioGroup>
            {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
        </FormControl>
    );
};

export default FormRadio;
