import React from "react";
import { GridCardActionsStyled, GridCardImageStyled, GridCardStyled, GridCardTitleStyled } from "./styled";

interface Props {
    image?: string;
    title: string;
    actions: React.ReactNode;
}
const GridCard: React.FC<Props> = ({ image, title, actions }) => {
    return (
        <GridCardStyled>
            <GridCardImageStyled>
                {image ? <img src={image} alt={title} /> : null}
                <GridCardActionsStyled>{actions}</GridCardActionsStyled>
            </GridCardImageStyled>
            <GridCardTitleStyled>{title}</GridCardTitleStyled>
        </GridCardStyled>
    );
};

export default GridCard;
